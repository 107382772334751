import { createStyles } from '@mantine/core';

type UseStylesProps = {
  entriesPercentage?: number;
  isMobile?: boolean;
};

const useStyles = createStyles((theme, { entriesPercentage, isMobile }: UseStylesProps) => ({
  stat: {
    ...(!isMobile
      ? {
          '&:not(:last-child)': {
            borderRight: `1px solid ${theme.colors.gray[6]}`,
          },
          paddingRight: theme.spacing.sm,
          paddingLeft: theme.spacing.sm,
          textAlign: 'center',
          minWidth: '110px',

          '&:first-of-type': {
            textAlign: 'left',
            paddingLeft: 0,
          },
          '&:last-child': {
            textAlign: 'right',
            paddingRight: 0,
          },
        }
      : {
          marginRight: theme.spacing.md,
        }),
  },
  statValue: {
    fontSize: isMobile ? theme.fontSizes.sm : theme.fontSizes.md,
  },
  statLabel: {
    fontSize: isMobile ? theme.fontSizes.xs : theme.fontSizes.sm,
    color: theme.colors.gray[6],
  },
  progressBar: {
    display: isMobile ? 'block' : 'none',

    height: '5px',
    width: '190px',
    borderRadius: '100em',
    overflow: 'hidden',
    position: 'relative',
    background: theme.colors.gray[7],
  },
  progressBarFill: {
    height: '100%',
    background: theme.colors[theme.primaryColor][4],
    ...(typeof entriesPercentage === 'number' &&
      entriesPercentage > 0 && {
        width: `${entriesPercentage * 100}%`,
      }),
  },
}));

export default useStyles;
