/* eslint-disable no-underscore-dangle */
import { parseStrapiImage, parseStrapiResponse } from '@betterpool/strapi-client';
import type { StrapiResponse } from '@betterpool/strapi-client';
import { validate } from 'uuid';

import { fetchStrapiJSON } from '../../fetchStrapiJSON';
import { StrapiCollection } from '../../enums/strapi.enum';

import type {
  CMSCarouselSlide,
  CMSCarouselSlideContest,
  CMSCarouselSlideGeneric,
  CarouselSlideContest,
  CarouselSlideGeneric,
} from './types';
import type { CarouselSpot } from './enums';
import { CarouselSlideType } from './enums';

type GetPromoBannerResponse = StrapiResponse<{
  slides: CMSCarouselSlide[];
  spot: CarouselSpot;
  location?: string[];
}>;

const parseGenericSlideImages = (
  slide: Pick<CMSCarouselSlideGeneric, 'backgroundImage' | 'rightImageMobile' | 'rightImageDesktop'>
) => ({
  ...(slide.backgroundImage && {
    backgroundImage: parseStrapiImage(slide.backgroundImage.data?.attributes),
  }),
  ...(slide.rightImageMobile && {
    rightImageMobile: parseStrapiImage(slide.rightImageMobile.data?.attributes),
  }),
  ...(slide.rightImageDesktop && {
    rightImageDesktop: parseStrapiImage(slide.rightImageDesktop.data?.attributes),
  }),
});

const SlideParser = {
  [CarouselSlideType.GENERIC]: (slide: CMSCarouselSlideGeneric): CarouselSlideGeneric => ({
    ...slide,
    ...parseGenericSlideImages(slide),
  }),
  [CarouselSlideType.CONTEST]: (slide: CMSCarouselSlideContest): CarouselSlideContest => ({
    ...slide,
    ...parseGenericSlideImages(slide),
  }),
};

const parseResponse = (response: GetPromoBannerResponse, spot: CarouselSpot, country?: string) => {
  const parsedStrapiResponse = parseStrapiResponse(response);

  const carousel = parsedStrapiResponse.find(({ __attributes }) => {
    if (
      country &&
      __attributes.location?.length &&
      !__attributes.location.map((c) => c.toLowerCase()).includes(country.toLowerCase())
    ) {
      return false;
    }

    return __attributes.spot === spot;
  });

  const slides = carousel?.__attributes.slides ?? [];

  return slides
    .filter((slide) => slide.__component !== CarouselSlideType.CONTEST || validate(slide.contestId))
    .map((slide) => {
      const parser = SlideParser[slide.__component];
      if (!parser) {
        return null;
      }
      if (slide.__component === CarouselSlideType.CONTEST) {
        slide.published = true;
      }

      // @ts-expect-error Unable to detect type for some reason
      return parser(slide);
    })
    .filter((slide) => slide !== null);
};

const getPromoCarousel = (spot: CarouselSpot, country?: string) =>
  fetchStrapiJSON<GetPromoBannerResponse>(
    StrapiCollection.SPLASH_PROMO_CAROUSEL,
    {
      populate: {
        slides: {
          populate: {
            actions: '*',
            backgroundImage: '*',
            rightImageDesktop: '*',
            rightImageMobile: '*',
          },
        },
      },
    },
    {
      spot,
    }
  ).then((response) => parseResponse(response, spot, country));

export default getPromoCarousel;
