const enum CarouselSlideTrafficType {
  ANONYMOUS = 'anonymous',
  USER = 'user',
}

const enum CarouselSlideType {
  GENERIC = 'carousel-slide-types.generic',
  CONTEST = 'carousel-slide-types.contest',
}

const enum CarouselSlideActionVariant {
  OUTLINE = 'outline',
  LIGHT = 'light',
  DEFAULT = 'default',
  FILLED = 'filled',
  SUBTLE = 'subtle',
}

const enum CarouselSlideActionIcon {
  INFO_CIRCLE = 'info-circle',
}

const enum CarouselSlideGenericAlign {
  LEFT = 'left',
  CENTER = 'center',
}

const enum CarouselSpot {
  LOBBY = 'lobby',
  HOMEHEADER = 'home-header',
  HOMEFOOTER = 'home-footer',
}

export {
  CarouselSlideTrafficType,
  CarouselSlideType,
  CarouselSlideActionVariant,
  CarouselSlideActionIcon,
  CarouselSlideGenericAlign,
  CarouselSpot,
};
