import React from 'react';
import { Divider, Group, Skeleton, Stack } from '@mantine/core';

import { useIsMobile } from '~/domains/common/hooks/mediaQueries';

import GenericSlide from '../GenericSlide/GenericSlide';

type ContestSlideLoadingProps = {
  isMobileVariant?: boolean;
};

function ContestSlideLoading({ isMobileVariant }: ContestSlideLoadingProps) {
  const isMobile = useIsMobile() || isMobileVariant;

  const titleHeight = isMobile ? 26 : 40;
  const statValueHeight = isMobile ? 16 : 20;
  const statLabelHeight = isMobile ? 12 : 16;
  const statValueWidth = isMobile ? 60 : 100;
  const statLabelWidth = isMobile ? 40 : 60;

  return (
    <GenericSlide
      isMobileVariant={isMobileVariant}
      title={
        <Stack spacing={2}>
          <Skeleton width="70%" height={titleHeight} />
          <Skeleton width="50%" height={titleHeight} />
        </Stack>
      }
      subtitle={
        <Stack>
          <Group>
            <Stack spacing={2}>
              <Skeleton width={statValueWidth} height={statValueHeight} />
              <Skeleton width={statLabelWidth} height={statLabelHeight} />
            </Stack>
            {!isMobile && <Divider orientation="vertical" />}
            <Stack spacing={2}>
              <Skeleton width={statValueWidth} height={statValueHeight} />
              <Skeleton width={statLabelWidth} height={statLabelHeight} />
            </Stack>
            {!isMobile && <Divider orientation="vertical" />}
            <Stack spacing={2}>
              <Skeleton width={statValueWidth} height={statValueHeight} />
              <Skeleton width={statLabelWidth} height={statLabelHeight} />
            </Stack>
          </Group>
          <Group spacing="xl">
            <Skeleton width={130} height={statValueHeight} />
            {!isMobile && <Skeleton width={150} height={statLabelHeight} />}
          </Group>
        </Stack>
      }
      actions={[]}
    />
  );
}

export default ContestSlideLoading;
