import React from 'react';
import { Box, Group, useMantineTheme } from '@mantine/core';
import type { EntriesDetails } from '@betterpool/api-types/contests-service';
import styled from '@emotion/styled';
import type { StrapiImage } from '@betterpool/strapi-client';
import useTranslation from 'next-translate/useTranslation';
import { useMediaQuery } from 'react-responsive';

import { CurrencyConvertor } from '~/components/utils/formatters';
import {
  CarouselSlideActionIcon,
  CarouselSlideActionVariant,
  CarouselSlideGenericAlign,
} from '~/domains/cms/promo/carousel.contests/enums';
import { URLQueryKeys } from '~/domains/common/types/URLQueryKeys';
import { Routes } from '~/domains/common/constants/routes';

import GenericSlide from '../GenericSlide/GenericSlide';
import GenericSlideTitle from '../GenericSlide/GenericSlideTitle';

import ContestSlideLoading from './ContestSlideLoading';
import useStyles from './ContestSlide.styles';
import EnterContestAction from './EnterContestAction';

const Title = styled(GenericSlideTitle)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type ContestSlideProps = {
  contestId: string;
  entries: EntriesDetails;
  entryFee: number;
  owner: string;
  prize: number;
  title: string;
  rightImageMobile?: StrapiImage;
  rightImageDesktop?: StrapiImage;
  backgroundImage?: StrapiImage;
  isLoading?: boolean;
  isError?: boolean;
  isMobileVariant?: boolean;
  contestStartDate?: Date;
  slideIndex: number;
  bannerLink?: string | null;
  onEnterContestClick?: () => void;
};

function ContestSlide({
  contestId,
  entries,
  entryFee,
  owner,
  prize,
  title,
  rightImageMobile,
  rightImageDesktop,
  backgroundImage,
  isLoading,
  isError,
  isMobileVariant,
  contestStartDate,
  slideIndex,
  bannerLink,
  onEnterContestClick,
}: ContestSlideProps) {
  const { t } = useTranslation('contest');
  const theme = useMantineTheme();
  const isMobile = useMediaQuery({ maxWidth: theme.breakpoints.sm }) || isMobileVariant;
  const { classes } = useStyles({
    entriesPercentage: entries ? entries.filled / entries.max : undefined,
    isMobile,
  });
  const rightImage = isMobile ? rightImageMobile : rightImageDesktop;
  const withImage = !!rightImage;

  if (isLoading) {
    return <ContestSlideLoading isMobileVariant={isMobileVariant} />;
  }

  if (isError) {
    return null;
  }

  return (
    <GenericSlide
      bannerLink={bannerLink}
      isMobileVariant={isMobileVariant}
      title={
        <Box>
          <Title withImage={withImage} isMobile={isMobile}>
            {title}
          </Title>
          <Title withImage={withImage} isMobile={isMobile}>
            {t('lobby.carousel.commissionerPrefix')} {owner}
          </Title>
        </Box>
      }
      subtitle={
        <>
          <div className={classes.progressBar}>
            <div className={classes.progressBarFill} />
          </div>
          <Group spacing={0}>
            <div className={classes.stat}>
              <div className={classes.statValue}>
                {entries.filled}/{entries.max}
              </div>
              <div className={classes.statLabel}>
                {t('lobby.carousel.entries', {
                  user: entries.user ?? 0,
                  max: entries.max_per_user,
                })}
              </div>
            </div>
            <div className={classes.stat}>
              <div className={classes.statValue}>{CurrencyConvertor(entryFee / 100)}</div>
              <div className={classes.statLabel}>{t('lobby.carousel.entry')}</div>
            </div>
            <div className={classes.stat}>
              <div className={classes.statValue}>{CurrencyConvertor(prize / 100)}</div>
              <div className={classes.statLabel}>{t('lobby.carousel.prizes')}</div>
            </div>
          </Group>
        </>
      }
      align={CarouselSlideGenericAlign.LEFT}
      actions={[
        <EnterContestAction
          key="1"
          contestId={contestId}
          entryFee={entryFee}
          onClick={onEnterContestClick}
        />,
        {
          id: 2,
          label: t('lobby.carousel.visitDetail'),
          variant: CarouselSlideActionVariant.SUBTLE,
          icon: CarouselSlideActionIcon.INFO_CIRCLE,
          href: Routes.contestDetail(contestId, {
            [URLQueryKeys.UTM_SOURCE]: 'homepage_carousel',
            [URLQueryKeys.UTM_CAMPAIGN]: `${slideIndex + 1}`,
          }),
          hideOnMobile: true,
        },
      ].filter(Boolean)}
      rightImageMobile={rightImageMobile}
      rightImageDesktop={rightImageDesktop}
      backgroundImage={backgroundImage}
      contestStartDate={contestStartDate}
    />
  );
}

export default ContestSlide;
