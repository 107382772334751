import { useQuery } from '@tanstack/react-query';
import { DEFAULT_STRAPI_STALE_TIME } from '@betterpool/strapi-client';
import { useContext } from 'react';

import { LocationContext } from '~/components/providers/LocationProvider';

import getPromoCarousel from './getPromoCarousel';
import type { CarouselSpot } from './enums';

const usePromoCarousel = (spot: CarouselSpot) => {
  const { locationCountryNameSafe, isLoading } = useContext(LocationContext);

  return useQuery({
    queryKey: ['cms@promoCarousel', locationCountryNameSafe, spot],
    queryFn: () => getPromoCarousel(spot, locationCountryNameSafe),
    staleTime: DEFAULT_STRAPI_STALE_TIME,
    enabled: !!locationCountryNameSafe && !isLoading,
  });
};

export default usePromoCarousel;
